













































































































import UserProfileApi from '@/includes/Api/UserProfile.api'
import { errorNotification } from '@/includes/NotificationService'
import UserProfileWeekDay from '@/components/UserProfile/charts/UserProfileWeekDay.vue'
import { UserProfile, UsersProfileStat, UserStat } from '@/includes/types/UserProfile/types'
import UserProfileHour from '@/components/UserProfile/charts/UserProfileHour.vue'
import UserProfileFullChart from './charts/UserProfileFullChart.vue'
import UserProfileJournalTimeline from '@/components/UserProfile/UserProfileJournalTimeline.vue'
import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import UserProfileInteracted from "@/components/UserProfile/UserProfileInteracted.vue";

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import VirtualList from 'vue-virtual-scroll-list'
import Vue from "vue";

@Component({
  components: {
    SetPeriod,
    UserProfileJournalTimeline,
    UserProfileHour,
    UserProfileWeekDay,
    UserProfileFullChart,
    UserMainInfo,
    Accordion,
    EmptyData,
    'virtual-list': VirtualList
  },
  data() {
    return {
      UserProfileInteracted
    }
  }
})
export default class UserProfileStatistics extends Vue {
  @Prop() user!: UserProfile

  svg = null

  period = {
    from: moment().add(-1, 'month').format('YYYY-MM-DD'),
    to:  moment().format('YYYY-MM-DD')
  }

  userStat: UserStat | null = null

  users: UsersProfileStat['users'] | null = null

  loading = false

  fullChartUpdateKey = 0

  getStat() {
    this.loading = true

    UserProfileApi.getUsersProfileStat(
      this.$store.getters.chatId,
      [ parseInt(this.$route.params.userId) ],
      this.period.from,
      this.period.to
    )
      .then(({ data }) => {
        this.userStat = data.data[0]
        this.users = data.users

        this.fullChartUpdateKey += 1;
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }

  mounted() {
    this.getStat()
  }
}
